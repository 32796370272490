<template>
  <div class="pagePadding">
    <div class="topDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span>通知单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight60" v-model="queryFrom.return_code"></i-input>
        </FormItem>
        <FormItem>
          <span>客户名称：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="supplierIndex" clearable filterable>
            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>下单时间：</span>
          <DatePicker :editable="false" class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker><span class="choseTimeZhi">至</span><DatePicker :editable="false" :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <br />
        <FormItem>
          <span>状态：</span
          ><Select class="iviewIptWidth250" v-model="queryFrom.return_status" clearable>
            <Option v-for="item in returnStatus" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addReturn" v-if="insertAble">新增</span>
        </FormItem>
      </Form>
    </div>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span style="margin-right: 20px;"
              >总数量：<span class="color389">{{ totalNum }}</span></span
            >
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>
        <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <!--    删除-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--     反冲-->
    <tips-component showModal="recoil" v-if="recoilStatus" @cancleBtn="recoilStatus = false" @sureBrn="sureRecoil"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name:'salesReturnConsign',
  components: {
    Table,
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      paging: null,
      clickId: null,
      status: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 4,
      typeList: [],
      recoilStatus: false,
      deleteStatus: false,
      isLoad: true,
      supplierIndex: -1,
      totalMoney: 0,
      totalNum: 0,
      supplierList: [],
      orderTypeList: [],
      orderStatusList: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '通知单号',
          align: 'center',
          key: 'return_code',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetail(param.row)
                    },
                  },
                },
                param.row.return_code
              ),
            ])
          },
          width: 200,
        },
        {
          title: '客户名称',
          align: 'center',
          key: 'customer_name',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class:'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '退货金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.return_price ? '¥' + param.row.return_price : '0')])
          },
          width: 200,
        },
        {
          title: '数量',
          key: 'return_amount',
          align: 'center',
          width: 120,
        },
        {
          title: '下单时间',
          sortable: 'custom',
          key: 'returnTime',
          align: 'center',
          width: 200,
        },
        {
          title: '状态',
          key: 'return_status',
          align: 'center',
          width: 120,
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            let status = param.row.return_status
            if (status == '草稿') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.edit(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '40px',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (status == '已完成') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.$Message.warning('功能开发中...')
                      },
                    },
                  },
                  '导出清单'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.return_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '待反冲' || status == '待收货') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.return_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '待反冲') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.return_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '40px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.return_code
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      total: 1,
      queryFrom: {
        page: 1,
        rows: 10,
        return_code: '',
        customer_id: '',
        customer_type: '',
        return_time_begin: '',
        return_time_end: '',
        return_status: '',
      },
      returnStatus: [
        {
          name: '草稿',
          value: '0',
        },
        {
          name: '待收货',
          value: 2,
        },
        {
          name: '待反冲',
          value: 3,
        },
        {
          name: '已完成',
          value: 1,
        },
      ],
      options: {},
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      itemInfo: {},
    }
  },
  activated () {
    if(sessionStorage.getItem('updataCache') == 0) {
       this.query()
       sessionStorage.setItem('updataCache','1')
    }
  },
  mounted() {
    this.query()
    this.getRightVisibal()
  },
  methods: {
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type}, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate,{template_type: this.template_type},true).then((res) => {
        if(res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$apiConsign.saleReturnDetail.url}?return_code=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '销售退货通知单.xlsx'
        aLink.click()
      })
    },
    sortChange(data) {
      console.log(data)
      if (data.key === 'returnTime') {
        this.queryFrom.sort_str = 'return_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    sureRecoil() {
      this.$http.put(this.$apiConsign.saleReturnRecoil, { return_code: this.itemInfo.return_code }, true).then(res => {
        this.recoilStatus = false
        this.query()
      })
    },
    // 点击反冲按钮
    recoil(row) {
      this.itemInfo = row
      this.recoilStatus = true
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.saleReturn, { return_code: this.itemInfo.return_code }, true).then(res => {
        this.query()
        this.deleteStatus = false
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.return_time_begin = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.return_time_begin) - 86400000
          },
        }
      } else {
        this.queryFrom.return_time_end = e
      }
    },
    // 点击删除
    deleteRow(item) {
      this.itemInfo = item
      this.deleteStatus = true
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.clientNameList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.clientNameList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.$http.get(this.$apiConsign.saleReturn, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_price
        this.totalNum = res.total_amount
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].returnTime = res.data[i].return_time ? this.$moment(res.data[i].return_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    addReturn() {
      this.$router.push({ path: '/salesReturnEditConsign' })
    },
    edit(row) {
      this.$router.push({
        path: '/salesReturnEditConsign',
        query: {
          id: row.return_code,
        },
      })
    },
    goDetail(row) {
      this.$router.push({
        path: '/salesReturnDetailConsign',
        query: {
          id: row.return_code,
        },
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.item{
  margin-bottom: 20px;
}
.marginRight60 {
  margin-right: 60px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;

    .form {
      height: 36px;
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
